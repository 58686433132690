import { RegisterCard } from "../components/RegisterCard";
import First from "../components/registerStaff/First";
import Second from "../components/registerStaff/Second";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { useState, useEffect } from "react";
import BackButton from "../components/backButton";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { defaultUrl } from "../features/actions";
import {
  uploadImageToCloudinary,
  validatePhoneNumber,
} from "../utilities/importantFns";
import { makeFetchRequest, makePostRequest } from "../utilities/common";

export const RegisterTeacher = ({ editForm }: any) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState(
    editForm ?? {
      surname: "",
      firstname: "",
      othername: "",
      gender: "",
      date_of_birth: "",
      email: "",
      phone_number: "",
      classroom_id: "",
      house_address: "",
      house_address2: "",
      city: "",
      state: "",
      country: "",
      // admission_date: "",
      zip_code: "",
      profile_picture: "",
      school_id: "",
      degree: "",
      discipline: "",
      class_type: "Secondary",
      institution: "DEFAULT",
      staff_type: "teaching",
      emergency_contact_name: "",
      emergency_contact_phone: "",
      emergency_contact_relationship: "",
    }
  );

  const [emergencyForm, setEmergencyForm] = useState<{
    contact_name: string;
    phone: string;
    relationship: string;
  }>({
    contact_name: "",
    phone: "",
    relationship: "",
  });

  const [step, setStep] = useState(1);

  const handleChange = ({ target: { name, value, files } }: any) => {
    // If it's a file input
    if (files && files.length > 0) {
      const file = files[0];

      if (file.size > 10000000) {
        toast.error(`File too large. ${file.name} exceeds the 9MB limit`);
        return;
      }

      if (!["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
        toast.error("Please provide a valid image");
        return;
      }

      setForm({ ...form, [name]: file });
    } else {
      // If it's a text input, update state normally
      setForm({ ...form, [name]: value });
    }
  };

  // const handleChange = ({ target: { name, value, files } }: any) => {
  //   if (files && files[0]?.size <= 10000000) {
  //     if (
  //       files[0]?.type === "image/jpeg" ||
  //       files[0]?.type === "image/png" ||
  //       files[0]?.type === "image/jpg"
  //     ) {
  //       setForm({ ...form, [name]: files ? files[0] : value });
  //     } else {
  //       toast.error("Please provide a valid image");
  //       return;
  //     }
  //   } else {
  //     toast.error(`File too large. ${files && files[0]?.name} exceeds the 9MB limit`)
  //     return;
  //   }
  // };

  const handleEmergencyChange = (e: any) => {
    setEmergencyForm({ ...emergencyForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    toast.loading("Registration in progress...");

    // GET THE CLASSROOM TEACHER WAS ASSIGNED TO
    const currentClassromm = await makeFetchRequest(
      `${defaultUrl}/classroom/${form.classroom_id}`
    );

    const formData = form;
    // if media is available
    let profile_pictureString;
    if (formData.profile_picture) {
      profile_pictureString =
        formData?.profile_picture &&
        (await uploadImageToCloudinary(formData.profile_picture));
      formData.profile_picture = profile_pictureString || "";
    }

    const body = {
      surname: form.surname.trim(),
      firstname: form.firstname.trim(),
      othername: form.othername.trim(),
      gender: form.gender.trim(),
      date_of_birth: form.date_of_birth.trim(),
      email: form.email.trim(),
      phone_number: form.phone_number.trim(),
      classroom_id: form.classroom_id.trim(),
      house_address: form.house_address.trim(),
      house_address2: form.house_address2.trim(),
      city: form.city.trim(),
      state: form.state.trim(),
      country: form.country.trim(),
      zip_code: form.zip_code.trim(),
      profile_picture: formData.profile_picture.trim(),
      school_id: form.school_id.trim(),
      degree: form.degree.trim(),
      discipline: form.discipline.trim(),
      class_type: form.class_type.trim(),
      institution: form.institution.trim(),
      staff_type: form.staff_type.trim(),
      emergency_contact_name: form.emergency_contact_name.trim(),
      emergency_contact_phone: form.emergency_contact_phone.trim(),
      emergency_contact_relationship:
        form.emergency_contact_relationship.trim(),
    };

    await makePostRequest(`${defaultUrl}/teachers/create`, body)
      .then(async (res) => {
        setLoading(false);
        if (res?.teacher_id) {
          const data = {
            name: currentClassromm.name,
            teachers: [...currentClassromm.teachers, res?.teacher_id],
          };
          // ASSIGN TEACHER TO THE CLASS
          await makePostRequest(
            `${defaultUrl}/classroom/${currentClassromm?.id}`,
            data
          );
          setForm({
            surname: "",
            firstname: "",
            othername: "",
            gender: "",
            date_of_birth: "",
            email: "",
            phone_number: "",
            classroom_id: "",
            house_address: "",
            house_address2: "",
            city: "",
            state: "",
            country: "",
            zip_code: "",
            profile_picture: "",
            school_id: "",
            institution: "DEFAULT",
            degree: "",
            discipline: "",
            class_type: "",
            staff_type: "",
            emergency_contact_name: "",
            emergency_contact_phone: "",
            emergency_contact_relationship: "",
          });
          navigate("/admin/staff/staff_data");
        } else {
          setForm({
            profile_picture: "",
          });
          navigate(1);
        }
        toast.remove();
      })
      .catch();

    toast.remove();
  };

  const nextStep = () => {
    if (
      !form.surname ||
      !form.firstname ||
      !form.othername ||
      !form.gender ||
      !form.date_of_birth ||
      !form.email ||
      !form.phone_number ||
      !form.classroom_id
    ) {
      toast.error("all fields are required");
      return;
    }

    const isPhoneValid = validatePhoneNumber(form.phone_number);
    if (!isPhoneValid) {
      toast.error("Invalid phone number");
      return;
    }

    setStep(step + 1);
  };

  const render = () => {
    switch (step) {
      case 1:
        return (
          <First
            nextStep={nextStep}
            handleChange={handleChange}
            form={form}
            next_form={() => {
              setStep(step + 1);
            }}
          />
        );

      case 2:
        return (
          <Second
            nextStep={nextStep}
            prevStep={() => {
              setStep(step - 1);
            }}
            handleChange={handleChange}
            form={form}
            handleSubmit={handleSubmit}
            loading={loading}
            handleEmergencyChange={handleEmergencyChange}
            emergencyForm={emergencyForm}
          />
        );

      default:
        return <First />;
    }
  };

  useEffect(() => {}, [step]);

  // COMPONENT TO RENDER
  return (
    <>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Create Staff Form" />
      </div>

      <div className="studentReg max-h-[90vh] overflow-y-auto register-scroll">
        <div className="studentRegisterCard">
          <RegisterCard
            title="Staff"
            details="Enter Staff data"
            activeDetails="Active Enrolled Staff"
            activeNumber={18}
          />
        </div>
        <div className="mainReg">{render()}</div>
      </div>
    </>
  );
};
