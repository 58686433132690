import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/white_logo.png";
import { CircleLoader } from "../components/CircleLoader";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../app/store";
import { registerThunk } from "../features/actions";

export const Register = () => {
  interface DetailsInterface {
    school_name: string;
    ceo_name: string;
    address: string;
    phone_number: string;
    package_subscribed: string;
    school_logo: File | null;
    surname: string;
    firstname: string;
    email: string;
    name: string;
  }

  const [loading, setLoading] = useState(false);
  const [signUp, setSignUp] = useState<DetailsInterface>({
    name: "",
    school_name: "",
    ceo_name: "",
    address: "",
    phone_number: "",
    school_logo: null,
    surname: "",
    firstname: "",
    email: "",
    package_subscribed: "",
  });

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSignUp({ ...signUp, [name]: value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSignUp({ ...signUp, school_logo: file, package_subscribed: "starter" });
  };
  console.log(signUp);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();

      // Ensure all values exist before appending
      if (signUp.school_name)
        formData.append("school_name", signUp.school_name);
      if (signUp.name) formData.append("name", signUp.name);
      if (signUp.ceo_name) formData.append("ceo_name", signUp.ceo_name);
      if (signUp.address) formData.append("address", signUp.address);
      if (signUp.phone_number)
        formData.append("phone_number", signUp.phone_number);
      else formData.append("phone_number", "");
      formData.append("package_subscribed", "starter");
      if (signUp.surname) formData.append("surname", signUp.surname);
      if (signUp.firstname) formData.append("firstname", signUp.firstname);
      if (signUp.email) formData.append("email", signUp.email);

      // Upload image if selected
      if (signUp.school_logo) {
        formData.append("school_logo", signUp.school_logo);
      }

      // Debugging: Check the FormData content before sending
      formData.forEach((value, key) => {
        console.log(`${key}:`, value);
      });

      // Dispatch the register action with FormData
      const result = await dispatch(registerThunk(formData)).unwrap();

      setLoading(false);

      if (typeof result === "string") {
        throw new Error(result);
      }

      toast.success("Registration successful!");
      navigate("/login");
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      toast.error(error.message || "Registration failed");
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      navigate("/admin");
    }
  }, []);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 h-screen bg-gray-100">
      {/* Left Side */}
      <div className="hidden md:flex flex-col items-center justify-center bg-blue-900 text-white p-10">
        <img src={logo} alt="Logo" className="mb-8 w-40" />
        <h2 className="text-2xl font-bold">Did you know?</h2>
        <p className="text-lg mt-3 text-center">
          “Education is the most powerful weapon which you can use to change the
          world.” - Nelson Mandela
        </p>
      </div>

      {/* Right Side */}
      <div className="flex items-center justify-center p-8">
        <div className="w-full max-w-lg bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-center text-2xl font-bold text-blue-900 mb-6">
            Register
          </h2>

          <form onSubmit={handleSubmit}>
            {/* Row 1 */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="text-gray-700 font-medium">School Name</label>
                <input
                  type="text"
                  name="school_name"
                  value={signUp.school_name}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label className="text-gray-700 font-medium">CEO Name</label>
                <input
                  type="text"
                  name="ceo_name"
                  value={signUp.ceo_name}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div>
                <label className="text-gray-700 font-medium">Surname</label>
                <input
                  type="text"
                  name="surname"
                  value={signUp.surname}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label className="text-gray-700 font-medium">Firstname</label>
                <input
                  type="text"
                  name="firstname"
                  value={signUp.firstname}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div>
                <label className="text-gray-700 font-medium">
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phone_number"
                  value={signUp.phone_number}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="mt-0">
                <label className="text-gray-700 font-medium">Email</label>
                <input
                  type="email"
                  name="email"
                  value={signUp.email}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>

              {/* <div>
                <label className="text-gray-700 font-medium">
                  Package Subscribed
                </label>
                <input
                  type="text"
                  name="packageSubscribed"
                  value={signUp.packageSubscribed}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div> */}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
              <div className="mt-0">
                <label className="text-gray-700 font-medium">School Logo</label>
                <input
                  type="file"
                  name="school_logo"
                  onChange={handleFileChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label className="text-gray-700 font-medium">Name</label>
                <input
                  type="text"
                  name="name"
                  value={signUp.name}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
            </div>

            <div className="mt-4">
              <label className="text-gray-700 font-medium">Address</label>
              <textarea
                name="address"
                value={signUp.address}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                rows={3}
                required
              ></textarea>
            </div>

            {/* Remember Me */}
            <label className="flex items-center py-3">
              <input type="checkbox" className="mr-2" />
              <p className="text-gray-700">Keep me signed in</p>
            </label>

            {/* Forgot Password */}
            <div className="w-full flex justify-end pb-3 text-blue-900 text-sm">
              <Link to="/forgot-password" className="hover:underline">
                Forgot Password?
              </Link>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-blue-900 text-white py-2 rounded flex justify-center items-center"
            >
              {loading ? <CircleLoader /> : "Register"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
