import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/report.css";

const Report = () => {
  return (
    <div>
      <div className="w-full">
        <TopAdminNav name="John Doe" title="Report" />
      </div>
      <div className="report">
        <div className="reportHead">
          <h2>Report for Class Activities</h2>
          <p>View and Filter School-wide Activity Reports</p>
        </div>
        <div className="reportContent">
          <div className="text-[#012063CC] mt-1 w-full overflow-x-auto register-scroll">
            <div className="w-full text-center">
              <div className="flex w-[98%] !min-w-[700px] mb-2 py-4 bg-[#F4F7FE] rounded-xl font-semibold border">
                <div className="w-2/12">Photo</div>
                <div className="w-3/12 text-[0.9em] font-bold flex justify-center">
                  Name
                </div>
                <div className="w-4/12 text-[0.9em] font-bold flex justify-start">
                  Contact 1
                </div>
                <div className="w-3/12 text-[0.9em] font-bold flex justify-start">
                  Contact 2
                </div>
              </div>
              <div className="h-full max-h-[64vh] w-[98%] !min-w-[700px]  overflow-auto">
                {[{}].map((item, index) => {
                  return (
                    <div className="border !min-w-[700px] text-center text-[#012063]/[0.7] text-gray-600 bg-white py-3 rounded-xl mt-[5px]">
                      <div className="flex items-center w-full border-blue-500">
                        <div className="w-2/12 text-[0.9em] font-bold flex justify-center min-h-[20px]">
                          <div className="h-20 w-20 overflow-auto rounded-full">
                            {"Love testing stuff"}
                          </div>
                        </div>
                        <div className="w-3/12">
                          <p className="text-center text-[15px] text-[#012063CC] font-semibold">
                            {"Love testing stuff"}
                          </p>
                        </div>
                        <div className="w-4/12">
                          <p className="text-left text-[0.85em]">
                            {"Love testing stuff"}
                          </p>
                        </div>
                        <div className="w-3/12">
                          <p className="text-left text-[0.85em]">
                            {"Love testing stuff"}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="reportHead">
          <h2>Report for Child</h2>
        </div>
        <div className="reportContent">
          {childReport.map((report, inx) => {
            return (
              <Link to={report.link} key={inx} className="reportDetai">
                <h2>{report.Name}</h2>
                <p>{report.content}</p>
              </Link>
            );
          })}
        </div>
        <div className="reportHead">
          <h2>Staff Report</h2>
        </div>
        <div className="reportContent">
          {staffReport.map((report, inx) => {
            return (
              <div key={inx} className="reportDetai">
                <h2>{report.Name}</h2>
                <p>{report.content}</p>
              </div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
};

export default Report;
