import { useEffect, useState } from "react";
import "../assets/css/setting.css";
import ellipsis from "../assets/ellipse.svg";
import { AiOutlinePlus } from "react-icons/ai";
import { defaultUrl } from "../features/actions";
import { useNavigate } from "react-router-dom";
import { makeFetchRequest, makePutRequest } from "../utilities/common";
import { uploadImageToCloudinary } from "../utilities/importantFns";
import toast from "react-hot-toast";

interface ImageObject {
  [key: string]: any;
}

const SchProfileSetting = () => {
  const navigate = useNavigate();
  let [form, setForm] = useState({
    id: "",
    school_name: "",
    address: "",
    ceo_name: "",
    phone_number: "",
    school_head_signature: "",
    school_logo: "",
    school_email: "",
    package_subscribed: "",
    max_students: 0,
  });

  const [imageUploaded, setImageUploaded] = useState<ImageObject>({
    //Image user uploaded
    school_logo: null,
    school_head_signature: null,
  });
  const [initialImage, setInitialImage] = useState<ImageObject>({
    //Image to display
    school_logo: null,
    school_head_signature: null,
  });

  async function getSchoolProfile() {
    const response = await makeFetchRequest(`${defaultUrl}/school`);
    setForm(response);
  }

  useEffect(() => {
    getSchoolProfile();
  }, []);

  function handleChange(e: any) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  // IMAGE ONCHAGE
  const handleImageUpload = (e: any) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (file) {
      setInitialImage((prev) => {
        return {
          ...prev,
          [name]: file,
        };
      });

      setImageUploaded((prev) => {
        return {
          ...prev,
          [name]: file,
        };
      });
    }
  };

  async function handleSubmit(e: any) {
    e.preventDefault();

    let imgUrl, signatureUrl;

    if (imageUploaded?.school_logo) {
      toast.loading("Uploading media...");
      imgUrl = await uploadImageToCloudinary(imageUploaded?.school_logo);
      toast.remove();
    }

    if (imageUploaded?.school_head_signature) {
      toast.loading("Uploading media...");
      signatureUrl = await uploadImageToCloudinary(
        imageUploaded?.school_head_signature
      );
      toast.remove();
    }

    const formData = form;
    const body = {
      school_name: formData.school_name?.trim(),
      address: formData.address?.trim(),
      ceo_name: formData.ceo_name?.trim(),
      phone_number: formData.package_subscribed?.trim(),
      school_logo: imgUrl || formData.school_logo,
      school_head_signature: signatureUrl || formData.school_head_signature,
    };

    await makePutRequest(`${defaultUrl}/school`, body);
  }

  return (
    <form onSubmit={handleSubmit} className="schProfile">
      <div className="inputDv flex-1">
        <div className="input">
          <label className="!text-sm">Name of School</label>
          <textarea
            name="school_name"
            value={form.school_name}
            onChange={(e) => handleChange(e)}
            className="px-2 py-1 text-sm text-gray-700"
          ></textarea>
        </div>
        <div className="inputOpt">
          <div className="input1">
            <label className="!text-sm">School Address</label>
            <input
              name="address"
              value={form.address}
              type="text"
              onChange={(e) => handleChange(e)}
              className="!h-12 px-2 py-1 text-sm text-gray-700"
            />
          </div>
          {/* <div className="input1">
            <label className="!text-sm">State</label>
            <input name="state" type="tel" className="!h-12" />
          </div> */}
        </div>
        <div className="inputOpt">
          <div className="input1">
            <label className="!text-sm">Phone Number</label>
            <input
              name="phone_number"
              type="tel"
              className="!h-12 px-2 py-1 text-sm text-gray-700"
              value={form?.phone_number === "starter" ? "" : form?.phone_number}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {/* <div className="input1">
            <label className="!text-sm">Phone number 2</label>
            <input
              name="phone_number_2"
              type="text"
              className="!h-12 px-2 py-1 text-sm text-gray-700"
              disabled
            />
          </div> */}
        </div>
        <div className="inputOpt">
          {/* <div className="input1">
            <label className="!text-sm">Email</label>
            <input
              name="school_email"
              type="Email"
              className="!h-12 px-2 py-1 text-sm text-gray-700"
              onChange={(e) => handleChange(e)}
              disabled
            />
          </div> */}
          <div className="input1">
            <label className="!text-sm">Package</label>
            <input
              name="package_subscribed"
              type="text"
              value={form.package_subscribed || ""}
              className="!h-12 px-2 py-1 text-sm text-gray-400 bg-gray-500 cursor-not-allowed"
              onChange={(e) => handleChange(e)}
              disabled
            />
          </div>
        </div>
        {/* <h1 className="text-sm text-gray-500 mt-6 mb-2 underline">
          Owner's Information
        </h1> */}
        <div className="inputOpt">
          <div className="input1">
            <label className="!text-sm">CEO/Owner's name</label>
            <input
              name="ceo_name"
              type="text"
              className="!h-12 px-2 py-1 text-sm text-gray-700"
              value={form.ceo_name || ""}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="input1">
          <label className="!text-sm">Head Teacher/Principal Signature</label>
          <label
            htmlFor="school_head_signature"
            className="!border-2 cursor-pointer relative h-[100px] w-60 flex justify-center items-center"
          >
            {!form.school_head_signature &&
            !initialImage?.school_head_signature ? (
              <div className="imageOutline">
                <AiOutlinePlus className="imageIcon" />
              </div>
            ) : (
              <div className="h-[100px] w-60">
                <img
                  className="w-full h-full"
                  src={
                    (initialImage?.school_head_signature &&
                      URL.createObjectURL(
                        initialImage?.school_head_signature
                      )) ||
                    form.school_head_signature
                  }
                  alt="preview"
                />
              </div>
            )}
          </label>

          <input
            type="file"
            id="school_head_signature"
            name="school_head_signature"
            accept=".png, .jpg, .jpeg"
            className="hidden"
            onChange={handleImageUpload}
          />
        </div>
      </div>
      <div className="ml-5 camera flex flex-col justify-between h-[80vh] max-w-max min-w-[280px] relative">
        <div className="m-5">
          <label className="!text-sm">School Logo</label>
          <label
            htmlFor="school_logo"
            className="!border-2 cursor-pointer relative h-[280px] w-[280px] flex justify-center items-center"
          >
            {!form.school_logo && !initialImage.school_logo ? (
              <div className="imageOutline">
                <AiOutlinePlus className="imageIcon" />
              </div>
            ) : (
              <div className="w-full h-full">
                <img
                  className="w-full h-full"
                  src={
                    (initialImage?.school_logo &&
                      URL.createObjectURL(initialImage?.school_logo)) ||
                    form.school_logo
                  }
                  alt="preview"
                />
              </div>
            )}
          </label>

          <input
            type="file"
            id="school_logo"
            name="school_logo"
            accept=".png, .jpg, .jpeg"
            className="hidden"
            onChange={handleImageUpload}
          />
        </div>

        <div className="cameraBtn">
          <button
            type="submit"
            className="!text-sm m-4 ml-0 !h-12 max-w-[200px]"
          >
            Save Changes
          </button>
          <button
            className="btn2 !text-sm m-4 ml-0 !h-12 max-w-[200px]"
            onClick={() => navigate("/admin")}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

export default SchProfileSetting;
