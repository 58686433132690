import "../../assets/css/studentRegistration.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";
import countryList from "react-select-country-list";
import { CircleLoader } from "../CircleLoader";

export default function Second({
  prevStep,
  handleChange,
  handleSubmit,
  form,
  loading,
  handleEmergencyChange,
  emergencyForm,
}: any) {
  const options = useMemo(() => countryList().getData(), []);

  return (
    <>
      <div className="indicator">
        <div className="firstInd cursor-pointer" onClick={prevStep}>
          1
        </div>
        <div className="firstInd cursor-pointer">2</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="regStudentFormBac">
          <div className="regStudentForm">
            <div>
              <h2>Address</h2>
              <div className="studentFormContent">
                <h4>
                  Name<span className="text-red-600">*</span>
                </h4>
                <div className="nameForm">
                  <input
                    type="text"
                    placeholder="House address*"
                    name="house_address"
                    value={form.house_address}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="studentFormContent">
                <div className="phoneOption">
                  <input
                    type="text"
                    placeholder="city*"
                    name="city"
                    value={form.city}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    placeholder="state*"
                    name="state"
                    value={form.state}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="studentFormContent">
                <div className="nameForm">
                  <select name="country" onChange={handleChange} required id="">
                    <option value="">Select Country</option>
                    <option value="Nigeria">Nigeria</option>
                    {options?.map((country: any) => {
                      return (
                        <option key={country.label} value={country.label}>
                          {country.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="studentFormContent">
                <h4>Zip/Postal</h4>

                <div className="phoneOption">
                  <input
                    type="text"
                    placeholder="zipcode"
                    name="zip_code"
                    value={form.zip_code}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="studentFormContent">
                <h4>Address 2</h4>
                <div className="phoneOption">
                  <textarea
                    placeholder="Address 2"
                    name="house_address2"
                    className="p-3 text-[14px] text-gray-500 border-2"
                    value={form.house_address2}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              <h2>Emergency Contact</h2>
              <div className="">
                <div className="studentFormContent">
                  <h4>
                    Contact<span className="text-red-600">*</span>
                  </h4>
                  <div className="nameForm">
                    <input
                      type="text"
                      placeholder="Name*"
                      name="emergency_contact_name"
                      onChange={handleChange}
                      value={form.emergency_contact_name}
                      required
                    />
                    <input
                      type="tel"
                      placeholder="Phone Number*"
                      name="emergency_contact_phone"
                      onChange={handleChange}
                      value={form.emergency_contact_phone}
                      required
                    />
                    <select
                      name="emergency_contact_relationship"
                      id=""
                      onChange={handleChange}
                      value={form.emergency_contact_relationship}
                      required
                    >
                      <option value="">Relationship</option>
                      <option>Father</option>
                      <option>Mother</option>
                      <option>Sister</option>
                      <option>Brother</option>
                      <option>Spouse</option>
                      <option>Partner</option>
                      <option>Others</option>
                    </select>

                    {/* <div className="cancel">
                      <FaTimes className="canIcon" />
                    </div> */}
                  </div>
                </div>
                {/* <div className="addButton">
                  <button className="!py-1">Add Emergency Number</button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="relative w-full flex justify-center pt-4">
            <div className="absolute top-0 sm:right-[40%]">
              <p className="w-full my-5 text-red-800 sm:text-xs">
                <b>⚠️</b> Image file size should not exceed 9MB
              </p>
            </div>

            <div className="imageInput">
              <input
                type="file"
                name="profile_picture"
                onChange={handleChange}
                required
              />
              {form.profile_picture && form.profile_picture instanceof Blob ? (
                <div className="flex flex-col">
                  <img
                    src={URL.createObjectURL(form.profile_picture)}
                    alt="profile"
                  />
                </div>
              ) : (
                <div className="imageOutline">
                  {/* <AiOutlinePlus className="imageIcon" /> */}
                  <FontAwesomeIcon
                    icon={faCameraRetro}
                    className="text-gray-100 text-[50px] animate-"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <button type="submit" className="studentRegBtn">

        </button> */}
        <button type="submit" className="studentRegBtn">
          {loading ? <CircleLoader /> : " Submit registration"}
        </button>
      </form>
    </>
  );
}
